import React from "react"
import Layout from "../../components/layout/layout"
import InnerWrapper from "../../components/layout/innerWrapper"
import { Row, Col } from "react-bootstrap"
import Breadcrumb from "react-bootstrap/Breadcrumb"
import { Link } from "gatsby"
import AboutSidebar from "../../components/layout/about-sidebar"
import SEO from "../../components/seo"

const CopyrightNoticePage = () => {
  return (
    <Layout>
      <SEO
        title={`Privacy Policy & Legal Notices | Walpole Outdoors`}
        description={`Walpole Outdoors provides a privacy policy outlining how information will be collected and what it will be used for, to ensure customer trust.`}
      />
      <InnerWrapper>
        <div className="page-hero"></div>

        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/about">About</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/about/copyright-notice">Copyright Notice</Link>
          </Breadcrumb.Item>
        </Breadcrumb>

        <Row className="generalcontent">
          <Col md={3}>
            <AboutSidebar></AboutSidebar>
          </Col>
          <Col md={9}>
            <h2 className="generalheader">Copyright Notice</h2>
          </Col>
        </Row>
      </InnerWrapper>
    </Layout>
  )
}

export default CopyrightNoticePage
